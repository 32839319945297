"use strict";

pos.factory("customerOrderWidgetService", function (customerOrderRepository, $q, receipt, articles, posConfig, backend) {
  var service = {};
  service.getCustomerOrders = function (searchParams) {
    return customerOrderRepository.api.getCustomerOrders(searchParams).then(function (response) {
      return response.data;
    });
  };
  function getArticleData(line) {
    var d = $q.defer();
    articles.findArticleById(line.itemNo).then(function (response) {
      d.resolve(response.data);
    });
    return d.promise;
  }
  service.GetAdditionalInfo = function (customerOrder) {
    var d = $q.defer();
    var promisses = [];
    customerOrder.customerOrderLines.forEach(function (line) {
      promisses.push(getArticleData(line));
    });
    $q.all(promisses).then(function (data) {
      customerOrder.customerOrderLines.forEach(function (line) {
        var article = data.find(function (a) {
          return a.itemNo == line.itemNo;
        });
        if (article) {
          line.article = article;
          line.article.disableDiscount = true;
          line.article.requiredToEnterManualPrice = false;
          line.article.discountDetails = [];
          line.article.itemAttributeValues = [];
          line.article.salesPrice = new Decimal(line.salesPrice);
          line.article.quantity = line.quantity;
          line.article.effectiveSalesPrice = new Decimal(line.salesPrice - line.taxAmount);
          line.article.taxAmount = line.taxAmount;
          line.article.vat = new Decimal(line.taxAmount / (line.salesPrice - line.taxAmount) * 100);
        }
      });
      d.resolve(customerOrder);
    });
    return d.promise;
  };
  service.saveCustomerOrder = function (customerOrder) {
    return customerOrderRepository.api.saveCustomerOrder(customerOrder).then(function (response) {
      return response.data;
    });
  };
  service.changeStatusCustomerOrder = function (id, status) {
    return customerOrderRepository.api.changeStatusCustomerOrder(id, status).then(function (response) {
      return response.data;
    });
  };
  service.addToReceipt = function (customerOrder) {
    customerOrder.showModal = false;
    receipt.addCustomerOrder(customerOrder);
  };
  service.printPickList = function (customerOrder) {
    if (!customerOrder) return;
    var deviceIndex = backend.defaultDevice;
    return customerOrderRepository.api.printPickList(deviceIndex, customerOrder, posConfig.printTranslations);
  };
  return service;
});
